import monk from '@monkvision/corejs';

import {
  AuthAudience,
  AuthClientId,
  AuthClientSecret,
  AuthGrantType,
  ApiUrl,
} from './config';

export const InspectionStatus = {
  Done: 'DONE',
  NotStarted: 'NOT_STARTED',
  Todo: 'TODO',
};

export const AuthOptions = {
  method: 'POST',
  body: {
    audience: AuthAudience,
    client_id: AuthClientId,
    client_secret: AuthClientSecret,
    grant_type: AuthGrantType,
  },
};

export const setupMonk = () => {
  const axiosConfig = {
    baseURL: ApiUrl,
    headers: { 'Access-Control-Allow-Origin': '*' },
  };

  monk.config.axiosConfig = axiosConfig;
};

const monitoringTracingOrigins = ['localhost', 'inspection.clearcar.com'];

if (process.env.REACT_APP_STAGING_URL) {
  monitoringTracingOrigins.push(process.env.REACT_APP_STAGING_URL);
}

export const monkMonitoringConfig = {
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV ?? 'development',
  debug: process.env.NODE_ENV !== 'production',
  tracesSampleRate: 0.025,
  tracingOrigins: monitoringTracingOrigins,
};

// Derived from Black Book vehicle class codes
const ClassCode = {
  SUV: 'P',
  Crossover: 'N',
  Sedan: 'C',
  City: 'A',
  Van: 'X',
  Minivan: 'W',
  Pickup: 'K',
};

export const getSightByTypeName = (name) => {
  switch (name) {
    case 'suv':
      return sightsSUV;
    case 'crossover':
      return sightsCrossover;
    case 'sedan':
      return sightsSedan;
    case 'city':
      return sightsCity;
    case 'van':
      return sightsVan;
    case 'minivan':
      return sightsMinivan;
    case 'pickup':
      return sightsPickup;
    default:
      return sightsSedan;
  }
};

/**
 * Determine the sights to display based on the vehicle class code
 *
 * List of sights can be found here: https://monkvision.github.io/monkjs/sights
 */
export const getSightIdsByClassCode = (classCode) => {
  switch (classCode) {
    case ClassCode.SUV:
      return sightsSUV;
    case ClassCode.Crossover:
      return sightsCrossover;
    case ClassCode.Sedan:
      return sightsSedan;
    case ClassCode.City:
      return sightsCity;
    case ClassCode.Van:
      return sightsVan;
    case ClassCode.Minivan:
      return sightsMinivan;
    case ClassCode.Pickup:
      return sightsPickup;
    default:
      return sightsSedan;
  }
};

const sightsInterior = [
  // '87539411', // Front Seats (Deprectated Sight)
  'ff87ff59', // Front Seats
  'ad16621c', // Interior Roof
  'rSvk2C', // Dashboard
  'ed8a75b6', // Dashboard from Driver Seat
  '50eebfb7', // Rear Seats
  '9c2d7b19', // Open Trunk
];

const sightsSUV = [
  'jgc21-QIvfeg0X', // Front Low
  'jgc21-KyUUVU2P', // Hood
  'jgc21-zCrDwYWE', // Front Bumper Left
  'jgc21-VHq_6BM-', // Front Full Left
  'jgc21-z15ZdJL6', // Front Lateral Low Left
  'jgc21-imomJ2V0', // Front Roof Left
  'jgc21-omlus7Ui', // Lateral Low Left
  'jgc21-3gjMwvQG', // Rear Lateral Low Left
  'jgc21-ezXzTRkj', // Rear Left
  'jgc21-TyJPUs8E', // Rear Low
  'jgc21-3JJvM7_B', // Rear Right
  'jgc21-RAVpqaE4', // Rear Lateral Low Right
  'jgc21-XXh8GWm8', // Lateral Low Right
  'jgc21-s7WDTRmE', // Front Lateral Low Right
  'jgc21-zkvFMHnS', // Front Full Right
  'jgc21-__JKllz9', // Front Bumper Right
  ...sightsInterior,
];

const sightsCrossover = [
  'fesc20-H1dfdfvH', // Front Low
  'fesc20-WMUaKDp1', // Hood
  'fesc20-LTe3X2bg', // Front Bumper Left
  'fesc20-bD8CBhYZ', // Front Full Left
  'fesc20-WIQsf_gX', // Front Lateral Low Left
  'fesc20-6GPUkfYn', // Front Roof Left
  'fesc20-fOt832UV', // Lateral Low Left
  'fesc20-4Wqx52oU', // Rear Lateral Low Left
  'fesc20-dfICsfSV', // Rear Left
  'fesc20-xBFiEy-_', // Rear Low
  'fesc20-LZc7p2kK', // Rear Right
  'fesc20-5Ts1UkPT', // Rear Lateral Low Right
  'fesc20-P0oSEh8p', // Lateral Low Right
  'fesc20-dKVLig1i', // Front Lateral Low Right
  'fesc20-0mJeXBDf', // Front Full Right
  'fesc20-Wzdtgqqz', // Front Bumper Right
  ...sightsInterior,
];

const sightsSedan = [
  'haccord-8YjMcu0D', // Front Low
  'haccord-DUPnw5jj', // Hood
  'haccord-hsCc_Nct', // Front Bumper Left
  'haccord-huAZfQJA', // Front Full Left
  'haccord-GQcZz48C', // Front Lateral Low Left
  'haccord-oiY_yPTR', // Front Roof Left
  'haccord-mdZ7optI', // Lateral Low Left
  'haccord-W-Bn3bU1', // Rear Lateral Low Left
  'haccord-GdWvsqrm', // Rear Left
  'haccord-6kYUBv_e', // Rear Low
  'haccord-Jq65fyD4', // Rear Right
  'haccord-OXYy5gET', // Rear Lateral Low Right
  'haccord-Gtt0JNQl', // Lateral Low Right
  'haccord-KN23XXkX', // Front Lateral Low Right
  'haccord-KvP-pm8L', // Front Full Right
  'haccord-Z84erkMb', // Front Bumper Right
  ...sightsInterior,
];

const sightsCity = [
  'ffocus18-XlfgjQb9', // Front Low
  'ffocus18-3TiCVAaN', // Hood
  'ffocus18-43ljK5xC', // Front Bumper Left
  'ffocus18-GgOSpLl6', // Front Full Left
  'ffocus18-x_1SE7X-', // Front Lateral Low Left
  'ffocus18-ZXKOomlv', // Front Roof Left
  'ffocus18-yo9eBDW6', // Lateral Low Left
  'ffocus18-S3kgFOBb', // Rear Lateral Low Left
  'ffocus18-9MeSIqp7', // Rear Left
  'ffocus18-L2UM_68Q', // Rear Low
  'ffocus18-jWOq2CNN', // Rear Right
  'ffocus18-P2jFq1Ea', // Rear Lateral Low Right
  'ffocus18-ts3buSD1', // Lateral Low Right
  'ffocus18-KkeGvT-F', // Front Lateral Low Right
  'ffocus18-seOy3jwd', // Front Full Right
  'ffocus18-lRDlWiwR', // Front Bumper Right
  ...sightsInterior,
];

const sightsVan = [
  'ftransit18-wyXf7MTv', // Front Low
  'ftransit18-UNAZWJ-r', // Hood
  'ftransit18-5SiNC94w', // Front Bumper Left
  'ftransit18-ffghVsNz', // Front Full Left
  'ftransit18-Y0vPhBVF', // Front Lateral Low Left
  'ftransit18-6khKhof0', // Lateral Low Left
  'ftransit18-3Sbfx_KZ', // Rear Lateral Low Left
  'ftransit18-iu1Vj2Oa', // Rear Left
  'ftransit18-aA2K898S', // Rear Up Left
  'ftransit18-3dkU10af', // Rear Low
  'ftransit18-cf0e-pcB', // Rear Up Right
  'ftransit18-FFP5b34o', // Rear Right
  'ftransit18-RJ2D7DNz', // Rear Lateral Low Right
  'ftransit18-eztNpSRX', // Lateral Low Right
  'ftransit18-4NMPqEV6', // Front Lateral Low Right
  'ftransit18--w_ir_yH', // Front Full Right
  'ftransit18-IIVI_pnX', // Front Bumper Right
  ...sightsInterior,
];

const sightsMinivan = [
  'tsienna20-YwrRNr9n', // Front Low
  'tsienna20-HykkFbXf', // Hood
  'tsienna20-TI4TVvT9', // Front Bumper Left
  'tsienna20-jY3cR5vy', // Front Full Left
  'tsienna20-65mfPdRD', // Front Lateral Low Left
  'tsienna20-is1tpnqR', // Front Roof Left
  'tsienna20-1LNxhgCR', // Lateral Low Left
  'tsienna20-670P2H2V', // Rear Lateral Low Left
  'tsienna20-1n_z8bYy', // Rear Left
  'tsienna20-qA3aAUUq', // Rear
  'tsienna20--a2RmRcs', // Rear Right
  'tsienna20-SebsoqJm', // Rear Lateral Low Right
  'tsienna20-Rw0Gtt7O', // Lateral Low Right
  'tsienna20-cI285Gon', // Front Lateral Low Right
  'tsienna20-MPCqHzeH', // Front Full Right
  'tsienna20-KHB_Cd9k', // Front Bumper Right
  ...sightsInterior,
];

const sightsPickup = [
  'ff150-zXbg0l3z', // Front Low
  'ff150-3he9UOwy', // Hood
  'ff150-KgHVkQBW', // Front Bumper Left
  'ff150-7UI3m9B3', // Front Full Left
  'ff150-FqbrFVr2', // Front Lateral Low Left
  'ff150-Ttsc7q6V', // Front Roof Left
  'ff150-vwE3yqdh', // Lateral Low Left
  'ff150-ouGGtRnf', // Rear Lateral Low Left
  'ff150--xPZZd83', // Rear Left
  'ff150-3dkU10af', // Rear Low
  'ff150-t3KBMPeD', // Rear Right
  'ff150-3rM9XB0Z', // Rear Lateral Low Right
  'ff150-18YVVN-G', // Lateral Low Right
  'ff150-7nvlys8r', // Front Lateral Low Right
  'ff150-3lKZIoxw', // Front Full Right
  'ff150-gFp78fQO', // Front Bumper Right
  ...sightsInterior,
];

const wheelAnalysisDamageDetectionSights = [
  { id: 'jgc21-z15ZdJL6', wheel_name: monk.types.WheelType.WHEEL_FRONT_LEFT },
  { id: 'jgc21-3gjMwvQG', wheel_name: monk.types.WheelType.WHEEL_BACK_LEFT },
  { id: 'jgc21-RAVpqaE4', wheel_name: monk.types.WheelType.WHEEL_BACK_RIGHT },
  { id: 'jgc21-s7WDTRmE', wheel_name: monk.types.WheelType.WHEEL_FRONT_RIGHT },

  { id: 'fesc20-WIQsf_gX', wheel_name: monk.types.WheelType.WHEEL_FRONT_LEFT },
  { id: 'fesc20-4Wqx52oU', wheel_name: monk.types.WheelType.WHEEL_BACK_LEFT },
  { id: 'fesc20-5Ts1UkPT', wheel_name: monk.types.WheelType.WHEEL_BACK_RIGHT },
  { id: 'fesc20-dKVLig1i', wheel_name: monk.types.WheelType.WHEEL_FRONT_RIGHT },

  { id: 'haccord-GQcZz48C', wheel_name: monk.types.WheelType.WHEEL_FRONT_LEFT },
  { id: 'haccord-W-Bn3bU1', wheel_name: monk.types.WheelType.WHEEL_BACK_LEFT },
  { id: 'haccord-OXYy5gET', wheel_name: monk.types.WheelType.WHEEL_BACK_RIGHT },
  {
    id: 'haccord-KN23XXkX',
    wheel_name: monk.types.WheelType.WHEEL_FRONT_RIGHT,
  },

  {
    id: 'ffocus18-x_1SE7X-',
    wheel_name: monk.types.WheelType.WHEEL_FRONT_LEFT,
  },
  { id: 'ffocus18-S3kgFOBb', wheel_name: monk.types.WheelType.WHEEL_BACK_LEFT },
  {
    id: 'ffocus18-P2jFq1Ea',
    wheel_name: monk.types.WheelType.WHEEL_BACK_RIGHT,
  },
  {
    id: 'ffocus18-KkeGvT-F',
    wheel_name: monk.types.WheelType.WHEEL_FRONT_RIGHT,
  },

  {
    id: 'ftransit18-Y0vPhBVF',
    wheel_name: monk.types.WheelType.WHEEL_FRONT_LEFT,
  },
  {
    id: 'ftransit18-3Sbfx_KZ',
    wheel_name: monk.types.WheelType.WHEEL_BACK_LEFT,
  },
  {
    id: 'ftransit18-RJ2D7DNz',
    wheel_name: monk.types.WheelType.WHEEL_BACK_RIGHT,
  },
  {
    id: 'ftransit18-4NMPqEV6',
    wheel_name: monk.types.WheelType.WHEEL_FRONT_RIGHT,
  },

  {
    id: 'tsienna20-65mfPdRD',
    wheel_name: monk.types.WheelType.WHEEL_FRONT_LEFT,
  },
  {
    id: 'tsienna20-670P2H2V',
    wheel_name: monk.types.WheelType.WHEEL_BACK_LEFT,
  },
  {
    id: 'tsienna20-SebsoqJm',
    wheel_name: monk.types.WheelType.WHEEL_BACK_RIGHT,
  },
  {
    id: 'tsienna20-cI285Gon',
    wheel_name: monk.types.WheelType.WHEEL_FRONT_RIGHT,
  },

  { id: 'ff150-FqbrFVr2', wheel_name: monk.types.WheelType.WHEEL_FRONT_LEFT },
  { id: 'ff150-ouGGtRnf', wheel_name: monk.types.WheelType.WHEEL_BACK_LEFT },
  { id: 'ff150-3rM9XB0Z', wheel_name: monk.types.WheelType.WHEEL_BACK_RIGHT },
  { id: 'ff150-7nvlys8r', wheel_name: monk.types.WheelType.WHEEL_FRONT_RIGHT },
];

export const mapTasksToSights = [
  ...wheelAnalysisDamageDetectionSights.map(({ id, wheel_name }) => ({
    id,
    tasks: [
      monk.types.TaskName.DAMAGE_DETECTION,
      {
        name: monk.types.TaskName.WHEEL_ANALYSIS,
        image_details: { wheel_name },
      },
    ],
    payload: {},
  })),
  /*
   * Ideally, pictures of interior sights should not have any tasks added to them. However, the Monk back-end does not
   * allow the addition of pictures without tasks. So for now, we need to add the damage_detection task temporarily.
   * This will affect the precision of the inspection results, but we have no other choice for now. The Monk back-end
   * team is currently working on a fix to allow task-less images, and once this fix is deployed, we will need to pass
   * an empty array to the "tasks" property below.
   *
   *   - 23/06/23, Samy Ouyahia, Monk Front-End team
   */
  ...sightsInterior.map((id) => ({
    id,
    tasks: [monk.types.TaskName.DAMAGE_DETECTION],
  })),
];
