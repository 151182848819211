import { useEffect } from 'react';
import { useSights } from '@monkvision/camera';

import { atsFireEvent, Event } from 'lib';

const InspectionSights = ({ inspectionId, storeId, vehicleId, sightIds }) => {
  const sights = useSights({ sightIds });

  useEffect(() => {
    const { current } = sights.state;

    if (inspectionId) {
      // Track first sight being loaded
      atsFireEvent(Event.LoadOverlay, {
        inspectionId,
        sightId: current.id,
        sightLabel: current.metadata.label.en,
        storeId,
        vehicleId,
      });
    }
  }, [sights.state.current, inspectionId]);

  return null;
};

export default InspectionSights;
