import React from 'react';
import styled from '@emotion/native';

const Alert = ({ isVisible = false, children }) =>
  isVisible && (
    <AlertContainer>
      <AlertText>{children}</AlertText>
    </AlertContainer>
  );

export default Alert;

const AlertContainer = styled.View`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) =>
    theme.color.ACVErrorAlertShades.uiErrorColorBase};

  padding: 10px;
`;

const AlertText = styled.Text`
  ${({ theme }) => theme.typography.caption}
  color: ${({ theme }) => theme.color.ACVErrorAlertShades.uiErrorColorXLight};
`;
