import { AnalyticsIsEnabled, AnalyticsApiKey } from './config';

const GTMSrcID = 'drvtrd-gtm-script';
const GTMFrameID = 'drvtrd-gtm-iframe';

export const Event = {
  EnableCamera: 'enable_camera',
  LoadOverlay: 'load_overlay',
  PhotoCaptureComplete: 'photo_capture_complete',
  SkipPhoto: 'skip_photo',
  Start: 'start',
  TakePhoto: 'take_photo',
  UploadPhoto: 'upload_photo',
};

/** Initialize analytics API */
export const atsInit = () => {
  if (AnalyticsIsEnabled) {
    // add GTM script
    if (!document.querySelector(`#${GTMSrcID}`)) {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? `&l=${l}` : '';

        j.id = GTMSrcID;
        j.async = true;
        j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;

        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', AnalyticsApiKey);
    }

    // add GTM iframe
    if (!document.querySelector(`#${GTMFrameID}`)) {
      const iframe = document.createElement('iframe');

      iframe.id = GTMFrameID;
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${AnalyticsApiKey}`;
      iframe.height = '0';
      iframe.width = '0';
      iframe.style = 'display:none;visibility:hidden';

      document.body.prepend(iframe);
    }
  }
};

/**
 * Trigger custom events
 *
 * @param {string} event - Custom event to fire (must an Event enum)
 * @param {object} data - Additional event data. Must define the properties
 *   passed here in GTM's data layer. See `/docs/analytics.md` for more
 *   details.
 */
export const atsFireEvent = (event, data = {}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ...data, event });
};
