import styled from '@emotion/native';
import React, { useEffect, useMemo, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import monk from '@monkvision/corejs';
import useFetch from 'use-http';
import { useQuery } from '@apollo/client';
import { logger } from 'lib/logger';

import {
  AuthUrl,
  DrivablyOfferPageUrl,
  errors,
  errorList,
  getSightIdsByClassCode,
  getVehicleUuid,
  HasuraEndpoint,
  mapTasksToSights,
  RoutePath,
  setupMonk,
  UsedCarClubUrl,
  ComplianceEnabledStores,
} from 'lib';
import { VehicleFindByUuidQuery } from 'gql';
import Alert from './Alert';
import Onboarding from './Onboarding';
import Inspection from './Inspection';
import Harness from './Harness';

setupMonk();

const UsedVehicleRoute = '/UsedVehicle';

const App = () => {
  const uuid = getVehicleUuid();

  const carClub = useFetch(UsedCarClubUrl);
  const auth = useFetch(
    AuthUrl,
    {
      onError: (error) => {
        logger.error(errors.failedToAuthenticate, {
          api: {
            req: {
              baseUrl: AuthUrl,
              body: JSON.stringify({ uuid }),
            },
            res: {
              error: JSON.stringify(error),
            },
          },
        });
      },
    },
    []
  );

  const [accessToken, setAccessToken] = useState(null);

  // Set to null so Capture will not render yet
  const [sightIds, setSightIds] = useState(null);

  const setSightIdsByVehicleClass = (classCode) => {
    const sightIdsForClass = getSightIdsByClassCode(classCode);
    setSightIds(sightIdsForClass);
  };

  const handleFetchVehicleComplete = (res) => {
    const { vin, year, make, model, style, trim } = res.vehicles?.[0] ?? {};

    // Set the right sights depending on the vehicle class (e.g. suv, sedan)
    const fetchClassType = async () => {
      let url = '';

      if (vin) {
        url = `${UsedVehicleRoute}/VIN/${vin}`;
      } else {
        url = `${UsedVehicleRoute}/${year}/${make}?model=${model}&style=${style}`;
        if (trim && trim !== 'None') {
          url = `${url}&series=${trim}`;
        }
      }

      const usedVehicleResponse = await carClub.get(url);
      const data =
        usedVehicleResponse?.used_vehicles?.used_vehicle_list?.[0] ?? {};

      setSightIdsByVehicleClass(data.class_code);
    };

    fetchClassType();
  };

  const {
    data,
    loading: isVehicleLoading,
    error,
  } = useQuery(VehicleFindByUuidQuery, {
    variables: { uuid },
    onCompleted: handleFetchVehicleComplete,
    onError: (err) => {
      // Set default sight ids when vehicle throws an error
      setSightIdsByVehicleClass('');

      logger.error(errors.failedToFindVehicle, {
        api: {
          req: {
            baseUrl: HasuraEndpoint,
            body: JSON.stringify({ uuid }),
          },
          res: {
            error: JSON.stringify(err),
          },
        },
      });
    },
  });

  useEffect(() => {
    if (auth.data?.token) {
      // Save token on monk lib to embed it on every api call
      monk.config.accessToken = auth.data?.token;

      // Save to state so components re-render with monk lib having accessToken in context
      setAccessToken(auth.data?.token);

      // store token in session storage so we can access it from harness page
      window.sessionStorage.setItem('accessToken', auth.data?.token);
    }
  }, [auth.data?.token]);

  const vehicle = data?.vehicles?.[0] ?? {};
  const vehicleInspectionRecord = vehicle.inspections?.[0] ?? {};
  const hasExistingInspection =
    Boolean(vehicleInspectionRecord.id) &&
    vehicleInspectionRecord.monk_inspection_status !== 'NOT_STARTED';
  const isDisabled =
    !uuid || !vehicle.id || !accessToken || hasExistingInspection;

  const AppContainer = useMemo(
    () => styled.View`
      min-height: 100vh;
    `,
    []
  );

  //Turn ON enableCompliance for ComplianceEnabledStores
  const enableCompliance = ComplianceEnabledStores.has(vehicle?.store?.id);

  const customerName =
    `${vehicle?.customer_first_name || ''}`.trim() || vehicle.contact?.name;

  return (
    <AppContainer>
      <Routes>
        <Route
          exact
          path={RoutePath.Home}
          element={
            <Onboarding
              name={customerName}
              storeId={vehicle.store?.id}
              logoUrl={vehicle?.store?.store_logo_url}
              enableLogo={vehicle?.store?.enable_inspection_logo}
            />
          }
        />
        <Route
          path={RoutePath.Inspection}
          element={
            <Inspection
              accessToken={accessToken}
              error={error || auth.error}
              isDisabled={isDisabled}
              sightIds={sightIds}
              mapTasksToSights={mapTasksToSights}
              uuid={uuid}
              vehicle={vehicle}
              enableCompliance={enableCompliance}
            />
          }
        />
        <Route path={RoutePath.Harness} element={<Harness />} />
      </Routes>
      {/* Inspection has already been done with this vehicle */}
      <Alert isVisible={hasExistingInspection}>
        You have already uploaded photos. Go straight to your{' '}
        <a style={{ color: '#fff' }} href={`${DrivablyOfferPageUrl}${uuid}`}>
          offer
        </a>
        .
      </Alert>
      {/* UUID is not associated with a vehicle */}
      <Alert isVisible={!isVehicleLoading && !vehicle.id}>
        {errorList.vehicleRecordNotFound}
      </Alert>
      {/* No UUID in the url */}
      <Alert isVisible={!uuid}>{errorList.vehicleNotIdentified}</Alert>
      {/* Unable to authenticate with Monk API */}
      <Alert isVisible={!accessToken && auth.error && !auth.loading}>
        {errorList.failedToAuthenticate}
      </Alert>
    </AppContainer>
  );
};

export default App;
