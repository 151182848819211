import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import { MonitoringProvider } from '@monkvision/corejs';

import { client, store, theme, atsInit, monkMonitoringConfig } from 'lib';
import App from 'components/App';

atsInit();

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <MonitoringProvider config={monkMonitoringConfig}>
            <App />
          </MonitoringProvider>
        </Router>
      </Provider>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById('root')
);
