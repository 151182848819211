export const StoreId = {
  AllCars: 348,
  Byrider: 102,
  ChernerBrothers: 106,
  ClevelandMaserati: 91,
  ClevelandMotorsports: 90,
  DestinationBurnaby: 59,
  Drivably: 7,
  iDriveCertified: 171,
  InfinitiMonrovia: 163,
  LeeCarsAugusta: 157,
  LeeCarsNorway: 158,
  LeeCarsSaco: 159,
  LeeChryslerJeepDodgeRamAuburn: 145,
  LeeCreditExpressAuburn: 155,
  LeeCreditNowBangorHammondSt: 150,
  LeeCreditNowBangorHoganRd: 151,
  LeeCreditNowBrewer: 152,
  LeeCreditNowEllsworth: 153,
  LeeCreditNowNewport: 156,
  LeeCreditNowOakland: 154,
  LeeDodgeChryslerJeepRamWestbrook: 149,
  LeeGMCAuburn: 146,
  LeeHondaAuburn: 147,
  LeeNissanAuburn: 148,
  LeeNissanofTopsham: 143,
  LeeToyotaTopsham: 144,
  NissanBuenaPark: 162,
  NissanDelRay: 170,
  NissanKendall: 8,
  NissanPuenteHills: 164,
  WindyCityMotors: 189,
  WindyCityBMW: 190,
};

export const getStoreMetadataById = (id) => {
  switch (id) {
    case StoreId.Byrider:
      return { logoSrc: '/image/logo-byrider.svg' };
    case StoreId.ChernerBrothers:
      return { logoSrc: '/image/logo-cherner-brothers.png' };
    case StoreId.ClevelandMaserati:
      return { logoSrc: '/image/logo-cleveland-maserati.png' };
    case StoreId.ClevelandMotorsports:
      return { logoSrc: '/image/logo-cleveland-motorsports.png' };
    case StoreId.iDriveCertified:
      return { logoSrc: '/image/logo-idrive-certified.png' };
    case StoreId.InfinitiMonrovia:
      return { logoSrc: '/image/logo-infiniti-monrovia.png' };
    case StoreId.LeeCreditExpressAuburn:
      return { logoSrc: '/image/logo-lee-credit-express.webp' };
    case StoreId.LeeCarsAugusta:
    case StoreId.LeeCarsNorway:
    case StoreId.LeeCarsSaco:
    case StoreId.LeeChryslerJeepDodgeRamAuburn:
    case StoreId.LeeCreditNowBangorHammondSt:
    case StoreId.LeeCreditNowBangorHoganRd:
    case StoreId.LeeCreditNowBrewer:
    case StoreId.LeeCreditNowEllsworth:
    case StoreId.LeeCreditNowNewport:
    case StoreId.LeeCreditNowOakland:
    case StoreId.LeeDodgeChryslerJeepRamWestbrook:
    case StoreId.LeeGMCAuburn:
    case StoreId.LeeNissanofTopsham:
      return { logoSrc: '/image/logo-lee-auto.png' };
    case StoreId.LeeHondaAuburn:
      return { logoSrc: '/image/logo-lee-honda-auburn.png' };
    case StoreId.LeeNissanAuburn:
      return { logoSrc: '/image/logo-lee-nissan-auburn.png' };
    case StoreId.LeeToyotaTopsham:
      return { logoSrc: '/image/logo-lee-toyota-topsham.webp' };
    case StoreId.NissanBuenaPark:
      return { logoSrc: '/image/logo-nissan-buena-park.png' };
    case StoreId.NissanDelRay:
      return { logoSrc: '/image/logo-nissan-del-ray.png' };
    case StoreId.NissanKendall:
      return { logoSrc: '/image/logo-nissan-kendall.png' };
    case StoreId.NissanPuenteHills:
      return { logoSrc: '/image/logo-nissan-puente-hills.png' };
    case StoreId.WindyCityMotors:
      return { logoSrc: '/image/logo-windy-city-demo.png' };
    case StoreId.WindyCityBMW:
      return { logoSrc: '/image/logo-windy-city.png' };
    // TODO cannot retrieve burnaby logo, site returns 403
    case StoreId.DestinationBurnaby:
    case StoreId.Drivably:
    default:
      return { logoSrc: '/image/logo-clear-car.svg' };
  }
};

export const ComplianceEnabledStores = new Set([
  StoreId.WindyCityMotors,
  StoreId.AllCars,
]);
