import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

/** Design tokens from Portal */
export const theme = {
  color: {
    Drivably: {
      primaryColor: '#2159F2',
      secondaryColorDark: '#072578',
    },
    backgrounds: {
      white: '#FFFFFF',
      borders: '#DCDFE8',
    },
    text: {
      secondary: '#00000099',
      text3: '#00000080',
    },
    ACVGreyShades: {
      uiWhiteColor: '#FFFFFF',
    },
    ACVBgLm: {
      background1Color: '#F1F1F1',
      background2Color: '#FAFAFA',
      border1Color: '#DCDFE8',
    },
    ACVTextLm: {
      textDisabledColor: '#A1A1A1',
      textPrimaryColor: '#212121',
      textSecondaryColor: '#545454',
    },
    ACVInformationalShades: {
      uiInformationalColorXLight: '#EBF6FF',
      uiInformationalColorBase: '#004E7D',
    },
    ACVPrimaryShadesLm: {
      primaryColorXLight: '#FEF2EC',
      primaryColorBase: '#F26522',
    },
    ACVErrorAlertShades: {
      uiErrorColorXLight: '#FFEBEB',
      uiErrorColorBase: '#FC4243',
    },
    ACVSuccessShadesLm: {
      uiSuccessColorXLight: '#F0FAF6',
      uiSuccessColorBase: '#00A878',
    },
    True360TextLM: {
      textInverseColor: '#FFFFFF',
    },
    // Unknown values in Figma
    raw: {
      white: '#FFFFFF',
      blackAlpha1: '#00000080',
      blackAlpha2: '#000000DE',
      gray1: '#DCDFE8',
      gray2: '#FAFAFA',
      gray3: '#E5E5E5',
      gray4: '#F3F3F4',
    },
  },
  typography: {
    GlobalHeading: {
      h4Bold: {
        fontFamily: 'Roboto',
        fontSize: '34px',
        fontWeight: '700',
        lineHeight: '40px',
        letterSpacing: '0.25px',
      },
      h5Bold: {
        fontFamily: 'Roboto',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '36px',
        letterSpacing: '0',
      },
      h6Medium: {
        fontFamily: 'Roboto',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '30px',
        letterSpacing: '0.15px',
      },
      h6Bold: {
        fontFamily: 'Roboto',
        fontSize: '20px',
        fontWeight: '700',
        lineHeight: '23px',
        letterSpacing: '0.15000000596046448px',
      },
      subtitleSmRegular: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '21px',
        letterSpacing: '0.1px',
      },
      subtitleBaseBold: {
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '24px',
        letterSpacing: '0.15px',
      },
    },
    GlobalText: {
      button: {
        fontFamily: 'Roboto',
        fontWeight: '500',
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '14px',
        letterSpacing: '1.25px',
      },
      bodyBaseRegular: {
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
      },
      body2Regular: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '16px',
        letterSpacing: '0.25px',
      },
      statusBadgeBase: {
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '12px',
        letterSpacing: '0.4px',
      },
      overline: {
        fontFamily: 'Roboto',
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '16px',
        letterSpacing: '1.5px',
      },
      overlineBold: {
        fontFamily: 'Roboto',
        fontSize: '10px',
        fontWeight: '700',
        lineHeight: '12px',
        letterSpacing: '1.5px',
      },
    },
    subtitle2: {
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '18px',
      letterSpacing: '0.1px',
    },
    caption: {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '18px',
      letterSpacing: '0.4px',
    },
    raw: {
      tableHeader: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '16px',
        letterSpacing: '0',
      },
      tableCell: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '16px',
        letterSpacing: '0px',
      },
      h1: {
        fontFamily: 'Roboto',
        fontSize: '39px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '36px',
        letterSpacing: '0',
      },
    },
  },
  custom: {},
};
