import React from 'react';

const IconBulb = () => (
  <svg
    width="36"
    height="34"
    viewBox="0 0 36 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.65 34.9V29.59H19.35V34.9H16.65ZM0 18.25V15.55H5.31V18.25H0ZM30.69 18.25V15.55H36V18.25H30.69ZM29.655 30.49L25.965 26.8L27.9 24.865L31.59 28.555L29.655 30.49ZM6.345 30.49L4.41 28.555L8.1 24.865L10.035 26.8L6.345 30.49ZM18 25.09C15.75 25.09 13.8225 24.2875 12.2175 22.6825C10.6125 21.0775 9.81 19.15 9.81 16.9C9.81 15.43 10.125 14.11 10.755 12.94C11.385 11.77 12.27 10.81 13.41 10.06V0.699951H22.59V10.06C23.73 10.81 24.615 11.77 25.245 12.94C25.875 14.11 26.19 15.43 26.19 16.9C26.19 19.15 25.3875 21.0775 23.7825 22.6825C22.1775 24.2875 20.25 25.09 18 25.09ZM16.11 9.11495C16.44 9.02495 16.7625 8.95745 17.0775 8.91245C17.3925 8.86745 17.7 8.84495 18 8.84495C18.33 8.84495 18.6525 8.86745 18.9675 8.91245C19.2825 8.95745 19.59 9.02495 19.89 9.11495V3.39995H16.11V9.11495ZM18 22.39C19.53 22.39 20.8275 21.8575 21.8925 20.7925C22.9575 19.7275 23.49 18.43 23.49 16.9C23.49 15.37 22.9575 14.0725 21.8925 13.0075C20.8275 11.9425 19.53 11.41 18 11.41C16.47 11.41 15.1725 11.9425 14.1075 13.0075C13.0425 14.0725 12.51 15.37 12.51 16.9C12.51 18.43 13.0425 19.7275 14.1075 20.7925C15.1725 21.8575 16.47 22.39 18 22.39Z"
      fill="black"
    />
  </svg>
);

export default IconBulb;
