export const AppName = 'Inspection';

export const isDev = process.env.NODE_ENV === 'development';

export const AnalyticsApiKey = process.env.REACT_APP_ANALYTICS_API_KEY;
export const AnalyticsIsEnabled =
  process.env.REACT_APP_ANALYTICS_IS_ENABLED === 'true';
export const ApiUrl = process.env.REACT_APP_API_DOMAIN;
export const AuthAudience = process.env.REACT_APP_AUTH_AUDIENCE;
export const AuthClientId = process.env.REACT_APP_AUTH_CLIENT_ID;
export const AuthClientSecret = process.env.REACT_APP_AUTH_CLIENT_SECRET;
export const AuthGrantType = process.env.REACT_APP_AUTH_GRANT_TYPE;
export const AuthUrl = process.env.REACT_APP_AUTH_URL;
export const DrivablyOfferPageUrl =
  process.env.REACT_APP_DRIVABLY_OFFER_PAGE_URL;
export const DrivablySelfInspectionThankYouPage =
  process.env.REACT_APP_DRIVABLY_SELF_INSPECTION_THANK_YOU_PAGE_URL;
export const HasuraEndpoint = process.env.REACT_APP_HASURA_GRAPHQL_ENDPOINT;
export const MonkCallbackUrl = process.env.REACT_APP_MONK_CALLBACK_URL;
export const UsedCarClubUrl = process.env.REACT_APP_USED_CAR_CLUB_URL;
export const LogKey = process.env.REACT_APP_PUBLIC_LOG_KEY;
export const LogEnabled = process.env.REACT_APP_PUBLIC_LOG_ENABLED === 'true';
export const LoggerAppSourceName = 'clearcar-inspection';
export const LogEnvironment = process.env.REACT_APP_PUBLIC_LOG_ENV;
