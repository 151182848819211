import { useState, useEffect } from 'react';

/**
 * Detect the current orientation of the device
 *
 * @returns {string} The orientation - portrait-primary, portrait-secondary
 *   (meaning upside down), landscape-primary and landscape-secondary (meaning
 *   upside down)
 */
export const useScreenOrientation = () => {
  const [orientation, setOrientation] = useState();

  useEffect(() => {
    const portrait = window.matchMedia('(orientation: portrait)');

    const handleOrientationChange = (e) => {
      setOrientation(e.matches ? 'portrait' : 'landscape');
    };

    handleOrientationChange(portrait);
    portrait.addEventListener('change', handleOrientationChange);

    return () => {
      portrait.removeEventListener('change', handleOrientationChange);
    };
  }, []);

  return {
    orientation,
    isLandscape: orientation?.startsWith('landscape'),
    isPortrait: orientation?.startsWith('portrait'),
  };
};
