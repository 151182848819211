import { gql } from '@apollo/client';

export const VehicleFindByUuidQuery = gql`
  query getVehicleID($uuid: uuid!) {
    vehicles(where: { uuid: { _eq: $uuid } }) {
      id
      make
      mileage: mi
      model
      store_id
      style
      trim
      vin
      year
      source_platform
      color: extra(path: "color")
      uvc: extra(path: "uvc")
      zip: extra(path: "zipCode")
      inspections {
        id
        monk_inspection_status
        monk_inspection_id
      }
      store {
        id
        max_dealer_id
        store_logo_url
        enable_inspection_logo
      }
      contact {
        name
      }
      customer_first_name
    }
  }
`;

export const InspectionCreateGql = gql`
  mutation insertInspection(
    $inspection_id: uuid!
    $vehicle_id: Int!
    $status: String!
  ) {
    insert_inspections_one(
      object: {
        vehicle_id: $vehicle_id
        monk_inspection_id: $inspection_id
        monk_inspection_status: $status
      }
    ) {
      id
    }
  }
`;

export const InspectionUpdateStatusGql = gql`
  mutation ChangeInspectionStatus($inspection_id: Int!) {
    update_inspections_by_pk(
      pk_columns: { id: $inspection_id }
      _set: { monk_inspection_status: "TODO" }
    ) {
      id
      vehicle {
        uuid
      }
      monk_inspection_status
    }
  }
`;
