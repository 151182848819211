import styled from '@emotion/native';
import React, { useState, useEffect, useCallback } from 'react';
import monk from '@monkvision/corejs';
import { Capture, Controls } from '@monkvision/camera';
import { utils } from '@monkvision/toolkit';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { logger } from 'lib/logger';

import {
  DrivablyOfferPageUrl,
  InspectionStatus,
  MonkCallbackUrl,
  atsFireEvent,
  Event,
  errors,
  HasuraEndpoint,
  DrivablySelfInspectionThankYouPage,
} from 'lib';
import { InspectionCreateGql, InspectionUpdateStatusGql } from 'gql';
import { useFullscreen } from '../hooks';
import Alert from './Alert';
import InspectionSights from './InspectionSights';

const RedirectDelay = 500;

// Monk capture component options
const CaptureOptions = {
  colors: {
    // Set overall camera and upload center background
    background: '#1e263f',
    // Set text color on upload center to contrast dark background
    text: '#fff',
  },
  compression: {
    quality: 0.9,
  },
  resolution: {
    QHDDelay: 2000,
  },
};

/** Which tool the vehicle was created from */
export const SourcePlatform = {
  Harness: 'harness',
  Manual: 'manual',
  QuickQuote: 'quick_quote',
  Widget: 'widget',
};

const Inspection = ({
  accessToken,
  error,
  isDisabled,
  mapTasksToSights,
  sightIds,
  uuid,
  vehicle,
  enableCompliance,
}) => {
  const dispatch = useDispatch();
  const { isFullscreen, requestFullscreen } = useFullscreen();

  const [loading, setLoading] = useState();
  const [success, setSuccess] = useState(false);
  const [inspectionCreated, setInspectionCreated] = useState(false);
  const [inspectionId, setInspectionId] = useState();
  const [drivablyInspectionId, protectedSetDrivablyInspectionId] = useState();
  const [hasDetectedCamera, setHasDetectedCamera] = useState(false);
  const [hasInspectionCreateError, setHasInspectionCreateError] =
    useState(false);

  const [createInspection, createInspectionRes] =
    useMutation(InspectionCreateGql);

  const handleUpdateInspectionCompleted = () => {
    atsFireEvent(Event.PhotoCaptureComplete, {
      inspectionId,
      storeId: vehicle?.store?.id,
      vehicleId: vehicle?.id,
    });

    setTimeout(() => {
      // For quick quote leads, redirect to dedicated thank you page
      if (vehicle?.source_platform === SourcePlatform.QuickQuote) {
        return window.location.assign(
          `${DrivablySelfInspectionThankYouPage}vehicle/${uuid}/thank-you`
        );
      }

      const URL =
        vehicle?.source_platform === SourcePlatform.Manual
          ? `${DrivablySelfInspectionThankYouPage}disclosures/thank-you`
          : `${DrivablyOfferPageUrl}${uuid}`;

      window.location = URL;
    }, RedirectDelay);
  };

  const [updateInspection, updateInspectionRes] = useMutation(
    InspectionUpdateStatusGql,
    {
      onCompleted: handleUpdateInspectionCompleted,
      onError: (err) => {
        logger.error(errors.failedToUpdateInspectionStatus, {
          api: {
            req: {
              baseUrl: HasuraEndpoint,
              body: JSON.stringify({
                uuid,
                inspection_id: inspectionId,
              }),
            },
            res: {
              error: JSON.stringify(err?.message),
            },
          },
        });
      },
    }
  );

  const setDrivablyInspectionId = (newId) => {
    protectedSetDrivablyInspectionId((existingId) => existingId || newId);
  };

  useEffect(() => {
    // Determine if inspection has been done for the vehicle
    const id = vehicle.inspections?.[0]?.id;
    if (id) {
      setDrivablyInspectionId(id);
    }
  }, [vehicle]);

  useEffect(() => {
    // Create inspection on monk and drivably
    // Skip if vehicle and access tokens are unavailable
    if (!accessToken || !vehicle.id || error) {
      return;
    }

    // Skip if inspection already created
    if (vehicle.inspections?.[0]?.monk_inspection_id) {
      setInspectionId(vehicle.inspections[0].monk_inspection_id);
      return;
    }

    // Skip if inspection created flag is toggled
    if (inspectionCreated) {
      return;
    }

    // Proceed with creating inspection
    const createInspectionRecords = async () => {
      const vehiclePayload = {
        brand: vehicle.make,
        color: vehicle.color,
        mileage: {
          unit: 'miles',
          value: vehicle.mileage,
        },
        model: vehicle.model,
        vin: vehicle.vin,
      };

      const metadata = {
        color: vehicle.color,
        model: vehicle.model,
        odometer: vehicle.mileage,
        store_id: vehicle.store_id,
        max_dealer_id: vehicle.store.max_dealer_id,
        style: vehicle.style,
        trim: vehicle.trim,
        uvc: vehicle.uvc,
        vehicle_id: vehicle.id,
        vin: vehicle.vin,
        zip: vehicle.zip,
      };

      const tasks = {
        damage_detection: {
          status: InspectionStatus.NotStarted,
          generate_subimages_damages: { generate_tight: true },
          generate_subimages_parts: { generate_tight: true },
          generate_visual_output: {
            generate_damages: true,
            generate_parts: true,
          },
          callbacks: [{ url: MonkCallbackUrl, params: {}, headers: {} }],
        },
        wheel_analysis: {
          status: InspectionStatus.NotStarted,
          use_longshots: true,
        },
      };

      let id = null;

      // Create a Monk inspection
      try {
        const createRes = await monk.entity.inspection.createOne({
          additionalData: metadata,
          tasks,
          vehicle: vehiclePayload,
          damage_severity: {
            output_format: 'toyota',
          },
        });

        id = createRes.id;

        if (!id) {
          logger.error(errors.failedToCreateInspectionNoIDGenerated, {
            api: {
              req: {
                body: JSON.stringify({ uuid, vin: vehicle?.vin }),
              },
            },
          });
          return setHasInspectionCreateError(true);
        }

        atsFireEvent(Event.Start, {
          inspectionId: id,
          storeId: vehicle?.store?.id,
          vehicleId: vehicle?.id,
        });

        setInspectionId(id);

        // Create a Drivably inspection
        createInspection({
          variables: {
            inspection_id: id,
            status: InspectionStatus.NotStarted,
            vehicle_id: vehicle.id,
          },
          onCompleted: (res) => {
            setInspectionCreated(true);
            setDrivablyInspectionId(res.insert_inspections_one.id);
          },
          onError: (err) => {
            setHasInspectionCreateError(true);

            logger.error(errors.failedToCreateInternalInspection, {
              api: {
                req: {
                  body: JSON.stringify({
                    uuid,
                    vin: vehicle?.vin,
                    inspection_id: id,
                    status: InspectionStatus?.NotStarted,
                    vehicle_id: vehicle?.id,
                  }),
                  url: HasuraEndpoint,
                },
                res: {
                  error: JSON.stringify(err?.message),
                },
              },
            });
          },
        });
      } catch (err) {
        setHasInspectionCreateError(true);

        logger.error(errors.failedToCreateInspection, {
          api: {
            req: {
              body: JSON.stringify({
                uuid,
                vin: vehicle?.vin,
                inspection_id: id,
                status: InspectionStatus?.NotStarted,
                vehicle_id: vehicle?.id,
              }),
              url: HasuraEndpoint,
            },
            res: {
              error: JSON.stringify(err?.message),
            },
          },
        });
      }
    };

    createInspectionRecords();
  }, [accessToken, vehicle, error, inspectionCreated]);

  useEffect(() => {
    // Process tasks after finishing the inspection
    if (inspectionId && success) {
      const handleSubmit = async () => {
        setLoading(true);

        try {
          const tasks = ['damage_detection', 'wheel_analysis'];

          const results = await Promise.all(
            tasks.map((taskName) =>
              monk.entity.task.updateOne(inspectionId, taskName, {
                status: InspectionStatus.Todo,
              })
            )
          );

          results.forEach((res) => {
            if (res.error) {
              throw res.error;
            }

            const { entities, result } = res;

            dispatch(
              monk.actions.gotOneTask({ entities, result, inspectionId })
            );
          });

          // Redirect back to offer page here
          await updateInspection({
            variables: {
              inspection_id: drivablyInspectionId,
            },
          });
        } catch (err) {
          utils.log([`Error after taking picture: ${err}`], 'error');
          setHasInspectionCreateError(true);

          logger.error(errors.failedToUpdateTask, {
            api: {
              req: {
                baseUrl: HasuraEndpoint,
                body: JSON.stringify({ inspection_id: drivablyInspectionId }),
              },
              res: {
                error: JSON.stringify(err?.message),
              },
            },
          });
        }

        setLoading(false);
      };

      handleSubmit();
    }
  }, [inspectionId, success]);

  const handleCameraPermissionSuccess = useCallback(() => {
    if (!hasDetectedCamera) {
      atsFireEvent(Event.EnableCamera, {
        inspectionId,
        storeId: vehicle?.store?.id,
        vehicleId: vehicle?.id,
      });
      setHasDetectedCamera(true);
    }
  }, [inspectionId, hasDetectedCamera, vehicle]);

  const handleFinishUploadPicture = (state) => {
    const { current, tour } = state.sights.state;

    atsFireEvent(Event.UploadPhoto, {
      inspectionId,
      sightId: current.id,
      sightLabel: current.metadata.label.en,
      storeId: vehicle?.store?.id,
      vehicleId: vehicle?.id,
    });

    // Track next sights being loaded
    const nextSight = tour[current.index + 1];
    if (nextSight) {
      atsFireEvent(Event.LoadOverlay, {
        inspectionId,
        sightId: nextSight.id,
        sightLabel: nextSight.label.en,
        storeId: vehicle?.store?.id,
        vehicleId: vehicle?.id,
      });
    }

    setLoading(false);
  };

  const handleStartUploadPicture = (state) => {
    const { current } = state.sights.state;
    const complianceState = state.compliance.state;

    const sightId = current.id;
    const isRetake = complianceState[sightId]?.requestCount >= 1;

    atsFireEvent(Event.TakePhoto, {
      inspectionId,
      isRetake,
      sightId,
      sightLabel: current.metadata.label.en,
      storeId: vehicle?.store?.id,
      vehicleId: vehicle?.id,
    });

    setLoading(true);
  };

  const isLoading =
    loading || createInspectionRes.loading || updateInspectionRes.loading;

  const isControlDisabled =
    Boolean(error) ||
    Controls.CaptureButtonProps.disabled ||
    hasInspectionCreateError ||
    isDisabled ||
    isLoading;

  const controls = [
    {
      disabled: isControlDisabled,
      ...Controls.getFullScreenButtonProps(isFullscreen),
      onPress: requestFullscreen,
    },
    {
      ...Controls.CaptureButtonProps,
      disabled: isControlDisabled,
    },
  ];

  const handleCaptureTourFinish = useCallback(() => {
    setLoading(false);
    if (!enableCompliance) {
      setSuccess(true);
    }
  }, [enableCompliance]);

  return (
    <>
      {sightIds && (
        <RootContainer>
          <Capture
            colors={CaptureOptions.colors}
            compressionOptions={CaptureOptions.compression}
            controls={controls}
            enableComplianceCheck={enableCompliance}
            inspectionId={inspectionId}
            loading={isLoading}
            mapTasksToSights={mapTasksToSights}
            onCameraPermissionSuccess={handleCameraPermissionSuccess}
            onCaptureTourFinish={handleCaptureTourFinish}
            onComplianceCheckFinish={() => setSuccess(true)}
            onFinishUploadPicture={handleFinishUploadPicture}
            onReady={() => setLoading(false)}
            onStartUploadPicture={handleStartUploadPicture}
            overlayPathStyles={{ strokeWidth: 3 }}
            resolutionOptions={CaptureOptions.resolution}
            sightIds={sightIds}
          />
          <InspectionSights
            inspectionId={inspectionId}
            sightIds={sightIds}
            storeId={vehicle?.store?.id}
            vehicleId={vehicle?.id}
          />
        </RootContainer>
      )}
      <Alert isVisible={hasInspectionCreateError}>
        Failed to create inspection. Please refresh the page.
      </Alert>
    </>
  );
};

const RootContainer = styled.View`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
`;

export default Inspection;
