export const errorList = {
  failedToAuthenticate: 'Failed to authenticate.',
  failedToFindVehicle: 'Failed to find vehicle by UUID.',
  failedToCreateInspection: 'Failed to create inspection.',
  failedToCreateInspectionNoIDGenerated:
    'Failed to create inspection. No id generated.',
  failedToCreateInternalInspection: 'Failed to create internal inspection.',
  failedToUpdateTask: 'Failed to update tasks.',
  failedToUpdateInspectionStatus: 'Failed to update inspection status',
  vehicleRecordNotFound: 'Vehicle record cannot be found.',
  vehicleNotIdentified: 'Vehicle to inspect cannot be identified.',
};

export const errors = Object.entries(errorList).reduce(
  (obj, [errorCode, errorMessage]) => ({
    ...obj,
    [errorCode]: {
      code: errorCode,
      message: errorMessage,
    },
  }),
  {}
);
