import { createLogger } from '@drivably-apps/component-lib';
import {
  isDev,
  LogEnvironment,
  LogKey,
  LoggerAppSourceName,
  LogEnabled,
} from './config';

export const logger = createLogger({
  apiKey: LogKey,
  appSource: LoggerAppSourceName,
  isConsoleEnabled: isDev,
  isServiceEnabled: LogEnabled,
  env: LogEnvironment,
});
