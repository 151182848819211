import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/native';

import { getStoreMetadataById, RoutePath } from 'lib';
import { useScreenOrientation } from 'hooks';
import LogoClearCar from './LogoClearCar';
import IconBulb from './IconBulb';
import IconLandscape from './IconLandscape';
import IconCar from './IconCar';

const Onboarding = ({ name, storeId, logoUrl, enableLogo }) => {
  const navigate = useNavigate();
  const { isPortrait, isLandscape } = useScreenOrientation();

  useEffect(() => {
    // Going full screen on firefox prevents the allow camera prompt to show up, so we ask early
    navigator.mediaDevices.getUserMedia({ video: true });
  }, []);

  const handleStartPress = () => {
    navigate({
      pathname: RoutePath.Inspection,
      search: window.location.search,
    });
  };

  const { logoSrc } = getStoreMetadataById(storeId);
  const firstName = name?.split(' ')?.[0] ?? name;

  return (
    <ScrollableContainer>
      <Container>
        {logoSrc && (
          <ImageContainer isPortrait={isPortrait} isLandscape={isLandscape}>
            <img src={logoSrc} />
          </ImageContainer>
        )}
        {enableLogo && logoUrl && (
          <ImageContainer isPortrait isStoreLogo>
            <img src={logoUrl} />
          </ImageContainer>
        )}
        {firstName && <Title>{`Hi ${firstName}!`}</Title>}
        <Headline>Here’s how to take the best</Headline>
        <Headline isLast isLandscape={isLandscape} isPortrait={isPortrait}>
          photos of your vehicle
        </Headline>
        <List isLandscape={isLandscape} isPortrait={isPortrait}>
          <ListItem isLandscape={isLandscape}>
            <ListItemIcon>
              <IconBulb />
            </ListItemIcon>
            <ListItemText>
              Park your car in a well lit space with
              <br />
              4-6 feet of room to walk around.
            </ListItemText>
          </ListItem>
          <ListItem hideOnLandscape isLandscape={isLandscape}>
            <ListItemIcon>
              <IconLandscape />
            </ListItemIcon>
            <ListItemText>Turn your phone on landscape mode.</ListItemText>
          </ListItem>
          <ListItem isLandscape={isLandscape}>
            <ListItemIcon>
              <IconCar />
            </ListItemIcon>
            <ListItemText>Fit your vehicle into the white lines.</ListItemText>
          </ListItem>
        </List>
        <Button onPress={handleStartPress} isLandscape={isLandscape}>
          <ButtonText>Start</ButtonText>
        </Button>
        <Footer isLandscape={isLandscape}>
          <FooterText>Powered by</FooterText>
          <LogoClearCar />
        </Footer>
      </Container>
    </ScrollableContainer>
  );
};

export default Onboarding;

const ScrollableContainer = styled.View`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  background: ${({ theme }) => theme.color.backgrounds.white};

  overflow: auto;
`;

const Container = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px;
`;

const ImageContainer = styled.View`
  & > img {
    width: 160px;
    height: auto;
  }

  margin-top: 65px;
  margin-bottom: 100px;

  ${({ isLandscape }) =>
    isLandscape &&
    `
    margin: 25px;
    width: 140px;
    margin-right: auto;
    margin-bottom: 30px;
  `}

  ${({ isPortrait }) => isPortrait && `margin: 50px 0`}
  ${({ isStoreLogo }) =>
    isStoreLogo && `margin-top: 10px; margin-bottom: 50px;`}
`;

const Title = styled.Text`
  ${({ theme }) => theme.typography.GlobalHeading.h4Bold}
  color: ${({ theme }) => theme.color.Drivably.primaryColor};

  text-align: center;

  margin-bottom: 23px;
`;

const Headline = styled.Text`
  ${({ theme }) => theme.typography.GlobalHeading.h6Bold}
  color: ${({ theme }) => theme.color.ACVTextLm.textPrimaryColor};

  text-align: center;

  ${({ isLast, isLandscape }) =>
    isLast && `margin-bottom: ${isLandscape ? '30px' : '40px'};`}
`;

const ListItem = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 24px;

  ${({ isLandscape, hideOnLandscape }) =>
    isLandscape &&
    `
      ${hideOnLandscape && `display: none;`}

      :first-child {
        margin-right: 40px;
      }
  `}
`;

const ListItemIcon = styled.View`
  margin-right: 25px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ListItemText = styled.Text`
  ${({ theme }) => theme.typography.GlobalText.body2Regular}
  color: ${({ theme }) => theme.color.text.secondary};
`;

const Button = styled.TouchableOpacity`
  padding: 16px;
  border-radius: 6px;

  ${({ isLandscape }) => `margin-bottom: ${isLandscape ? '18px' : '66px'};`}

  height: 46px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ disabled, theme }) =>
    disabled
      ? theme.color.ACVBgLm.background1Color
      : theme.color.Drivably.primaryColor};

  width: 200px;
`;

const ButtonText = styled.Text`
  ${({ theme }) => theme.typography.GlobalText.button}

  color: ${({ disabled, theme }) =>
    disabled ? theme.color.ACVTextLm.textDisabledColor : theme.color.raw.white};

  text-transform: uppercase;
`;

const List = styled.View`
  margin-bottom: 142px;

  ${({ isLandscape }) =>
    isLandscape &&
    `
      display: flex;
      flex-direction: row;
      margin-bottom: 26px;
  `}
  ${({ isPortrait }) => isPortrait && `margin-bottom: 66px`}
`;

const Footer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 38px;

  ${({ isLandscape }) =>
    isLandscape &&
    `
    margin-left: auto;
    margin-right: 23px;
  `}
`;

const FooterText = styled.Text`
  ${({ theme }) => theme.typography.GlobalText.overlineBold}

  margin-right: 10px;
  text-transform: uppercase;
`;
