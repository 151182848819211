import React from 'react';

const IconLandscape = () => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.0332 3.675C28.8019 5.93542 32.2144 10.5583 32.7394 16.0417H34.9269C34.1832 7.05833 26.6728 0 17.4998 0L16.5373 0.04375L22.0936 5.6L24.0332 3.675ZM14.9186 2.55208C14.0582 1.69167 12.6728 1.69167 11.8269 2.55208L2.55192 11.8271C1.6915 12.6875 1.6915 14.0729 2.55192 14.9187L20.0811 32.4479C20.9415 33.3083 22.3269 33.3083 23.1728 32.4479L32.4478 23.1729C33.3082 22.3125 33.3082 20.9271 32.4478 20.0813L14.9186 2.55208ZM21.6269 30.9021L4.09775 13.3729L13.3728 4.09792L30.9019 21.6271L21.6269 30.9021ZM10.9665 31.325C6.19775 29.0792 2.78525 24.4417 2.26025 18.9583H0.0727539C0.816504 27.9417 8.32692 35 17.4998 35L18.4623 34.9562L12.9061 29.4L10.9665 31.325Z"
      fill="black"
    />
  </svg>
);

export default IconLandscape;
