import React from 'react';

const IconCar = () => (
  <svg
    width="28"
    height="25"
    viewBox="0 0 28 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.1491 2.30617C23.8558 1.44575 23.0345 0.833252 22.0665 0.833252H5.93314C4.96514 0.833252 4.15847 1.44575 3.85047 2.30617L0.799805 11.0416V22.7083C0.799805 23.5103 1.4598 24.1666 2.26647 24.1666H3.73314C4.5398 24.1666 5.1998 23.5103 5.1998 22.7083V21.2499H22.7998V22.7083C22.7998 23.5103 23.4598 24.1666 24.2665 24.1666H25.7331C26.5398 24.1666 27.1998 23.5103 27.1998 22.7083V11.0416L24.1491 2.30617ZM5.93314 16.8749C4.7158 16.8749 3.73314 15.8978 3.73314 14.6874C3.73314 13.477 4.7158 12.4999 5.93314 12.4999C7.15047 12.4999 8.13314 13.477 8.13314 14.6874C8.13314 15.8978 7.15047 16.8749 5.93314 16.8749ZM22.0665 16.8749C20.8491 16.8749 19.8665 15.8978 19.8665 14.6874C19.8665 13.477 20.8491 12.4999 22.0665 12.4999C23.2838 12.4999 24.2665 13.477 24.2665 14.6874C24.2665 15.8978 23.2838 16.8749 22.0665 16.8749ZM3.73314 9.58325L5.93314 3.02075H22.0665L24.2665 9.58325H3.73314Z"
      fill="black"
    />
    <path
      d="M23.6759 2.46752L23.6771 2.47102L26.6998 11.1264V22.7083C26.6998 23.2315 26.2664 23.6666 25.7331 23.6666H24.2665C23.7333 23.6666 23.2998 23.2315 23.2998 22.7083V21.2499V20.7499H22.7998H5.1998H4.6998V21.2499V22.7083C4.6998 23.2315 4.26635 23.6666 3.73314 23.6666H2.26647C1.73326 23.6666 1.2998 23.2315 1.2998 22.7083V11.1264L4.32183 2.47297C4.56234 1.80325 5.18638 1.33325 5.93314 1.33325H22.0665C22.8191 1.33325 23.4512 1.80832 23.6759 2.46752ZM3.25907 9.42433L3.03817 10.0833H3.73314H24.2665H24.9614L24.7405 9.42433L22.5405 2.86183L22.4262 2.52075H22.0665H5.93314H5.57341L5.45907 2.86183L3.25907 9.42433ZM3.23314 14.6874C3.23314 16.1767 4.44235 17.3749 5.93314 17.3749C7.42392 17.3749 8.63314 16.1767 8.63314 14.6874C8.63314 13.1982 7.42392 11.9999 5.93314 11.9999C4.44235 11.9999 3.23314 13.1982 3.23314 14.6874ZM19.3665 14.6874C19.3665 16.1767 20.5757 17.3749 22.0665 17.3749C23.5573 17.3749 24.7665 16.1767 24.7665 14.6874C24.7665 13.1982 23.5573 11.9999 22.0665 11.9999C20.5757 11.9999 19.3665 13.1982 19.3665 14.6874Z"
      stroke="black"
      strokeOpacity="0.87"
    />
  </svg>
);

export default IconCar;
